<template>
  <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'accessory' }">Accessories Management</router-link>
        </li>
        <li class="breadcrumb-item active">Assign</li>
      </ol>
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row">
        <div class="col-md-11">
          <h2 class="title2">Assign Accessories</h2>
        </div>
        <div class="col-md-1 text-right">
          <router-link
            :to="{ name: 'accessories' }"
            class="btn btn-outline-primary"
            >Back</router-link
          >
        </div>
      </div>
      <div v-if="this.$store.state.loader">
        <loader
          object="#f74b3d"
          color1="#ffffff"
          color2="#17fd3d"
          size="5"
          speed="2"
          bg="#343a40"
          objectbg="#999793"
          opacity="80"
          disableScrolling="false"
          name="spinning"
        ></loader>
      </div>
      <form v-else @submit.prevent="onSubmit">
        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <div class="card-header">
              </div>
              <div class="card-body">
                 <div class="mb-3">
                  <label class="form-label">Accessory Name</label>
                  <input
                    type="text"
                    placeholder="Accessory name"
                    class="form-control"
                    v-model="$v.form.accessory_name.$model"
                    :validator="$v.form.accessory_name"
                  >
                  <span
                    class="error "
                    v-if="$v.form.accessory_name.$error == true"
                    >Accessory name is required</span
                  >
                </div>
                <div class="mb-3">
                  <label class="form-label">Model</label>
                  <input
                    type="text"
                    placeholder="Model name/number"
                    class="form-control"
                    v-model="$v.form.model.$model"
                    :validator="$v.form.model"
                  >
                  <span
                    class="error "
                    v-if="$v.form.model.$error == true"
                    >Model is required</span
                  >
                </div>
                <div class="mb-3">
                  <label class="form-label">Quantity</label>

                  <input
                    type="number"
                    placeholder="Quantity"
                    class="form-control"
                    v-model="$v.form.quantity.$model"
                    :validator="$v.form.quantity"
                  >
                  <span
                    class="error "
                    v-if="$v.form.quantity.$error == true"
                    >Quantity is required</span
                  >
                </div>
                <div class="mb-3">
                  <label class="form-label">Assigning to</label>
                  <b-form-select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="$v.form.assigned_to.$model"
                    :validator="$v.form.assigned_to"
                    :options="users"
                  ></b-form-select>
                  <span
                    class="error "
                    v-if="$v.form.assigned_to.$error == true"
                    >Please select user
                  </span>
                </div>
                <div class="text-right">
                  <button type="submit" class="btn btn-outline-primary space">
                    Save
                  </button>
                  <router-link
                    :to="{ name: 'accessories' }"
                    class="btn btn-outline-primary"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { required, sameAs } from "vuelidate/lib/validators";
import moment from 'moment';
import axios from 'axios';
export default {
  data() {
    return {
      form: {
        accessory_name: null,
        model: null,
        quantity: null,
        assigned_to: null,
      },
    };
  },
  validations: {
    form: {
      accessory_name: {
        required,
      },
      assigned_to: {
        required
      },
      model: {
        required,
      },
      quantity: {
        required,
      }
    },
  },
  created() {
    this.$store.dispatch("switchLoader", true);
    this.$store.dispatch("loadUsers");
  },
  mounted() {
  },
  computed: {
    users() {
      return this.$store.state.users.users.map((user) => {
        return {
          value: user.idno,
          text: user.name
        };
      });
    },
  },
  methods: {
    timeChangeHandler(event,time){
        if(time == 'timein'){
            this.form.timein = event.displayTime;
        }
        else{
            this.form.timeout = event.displayTime;
        }
        
    },
    
    customFormatter(date) {
      return moment(date).format('yyyy-MM-DD');
    },
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      console.log(this.form);
      axios
        .post("/accessories/assign", this.form)
        .catch(({ response }) => {
        })
        .then(({ data }) => {
          if (data.success) {
            this.flashMessage.success({
              message: data.success,
              time: 3000,
            });
            this.$router.push({ name: "accessories" });
          } else {
            this.flashMessage.error({
              message: data.data.error,
              time: 3000,
            });
          }
        });
    },
    onChangeFileUpload() {
      this.form.image = this.$refs.file.files[0];
    },
  },
};
</script>